<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">系统设置</el-breadcrumb-item>
        <el-breadcrumb-item>部门管理</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="forms" ref="forms" label-width="70px" class="search-form">
      <el-row>
        <el-form-item label="所属公司" prop="keyword">
          <el-col>
            <el-tree-select v-model="forms.companyID" :data="companys" check-strictly :render-after-expand="false"
              @change="search" style="min-width: 220px;"  />
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="search()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <div class="toolbar">
      <el-button type="primary" icon="plus" @click="add">
        新增
      </el-button>
      <el-button type="primary" plain icon="fold" @click="toggleRowExpand">
        展开
      </el-button>
      <el-button type="primary" plain icon="Expand" @click="toggleRowExpand">
        折叠
      </el-button>
    </div>

    <el-table class="table" ref="table" :data="treeData" row-key="id" border :default-expand-all="expand" size="small"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
      <el-table-column type="index" label="序号" align="center" width="60">
      </el-table-column>
      <el-table-column prop="departmentName" label="部门全称" width="190">
      </el-table-column>
      <el-table-column prop="shortName" label="部门简称" width="120">
      </el-table-column>
      <el-table-column prop="createDate" label="创建时间" width="145">
      </el-table-column>
      <el-table-column prop="createUserName" label="创建人" width="100">
      </el-table-column>
      <el-table-column prop="sortCode" label="排序号" width="90">
      </el-table-column>
      <el-table-column prop="remark" label="备注">
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="190">
        <template #default="scope">
          <el-button @click="add(scope.row)" type="primary" link size="small">
            新增子部门
          </el-button>
          <el-button type="primary" link size="small" @click="edit(scope.row)">
            编辑
          </el-button>
          <el-button type="primary" link size="small" icon="delete" @click="del(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

  <el-dialog title="部门信息维护" width="600px" destroy-on-close v-model="formDialogVisible" @close="close">
    <el-form :model="formData" :rules="formRules" ref="formRef" :label-width="formLabelWidth">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="所属公司">
            <el-tree-select v-model="formData.companyID" :data="companys" check-strictly disabled
              :render-after-expand="false" />
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="上级部门">
            <!-- <el-select v-model="forms.parentID" placeholder="请选择上级菜单" disabled>
              <el-option label="根菜单" value=""></el-option>
            </el-select> -->
            <el-tree-select v-model="formData.parentID" :data="parentSelect" check-strictly
              :render-after-expand="false" />
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="部门全称" prop="departmentName">
            <el-input v-model="formData.departmentName" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>


        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="部门简称" prop="shortName">
            <el-input v-model="formData.shortName" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>


        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="排序号">
            <el-input-number v-model="formData.sortCode" :min="1" :max="9999999" label="排序号"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="备注信息" prop="remark">
            <el-input v-model="formData.remark" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="formDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">
          确 定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { submitDepartment, delDepartment } from "@/api/basic";
export default {
  name: "Department",
  components: {},
  data() {
    return {
      forms: {
        keyword: "",
      },
      expand: false, //默认展开所有节点
      treeData: [],
      formDialogVisible: false,
      formData: {},
      formLabelWidth: "90px",
      formRules: {
        departmentName: [
          { required: true, message: "请输入部门的全称", trigger: "blur" },
          { min: 1, max: 50, message: "长度在1-40之间", trigger: "blur" },
        ],
      },

      parentSelect: [],
      companys: [],
    };
  },
  methods: {
    edit(row) {
      this.formDialogVisible = true;
      this.formData = row;
    },
    add(row) {
      //const res=await  this.$http.post('url',this.formData)
      //success

      this.formDialogVisible = true;
      if (row && (row.id || '').length > 0)
        this.formData = { parentID: row.id, companyID: this.forms.companyID };
      else this.formData = { companyID: this.forms.companyID };
    },
    close() {
      this.$refs.formRef.resetFields();
    },
    getTree(companyId) {
      var that = this;

      // this.$kaung.departments(companyId).then((res) => {
      //   that.treeData = res;
      //   that.parentSelect = res;
      // });
      this.$kaung.fetch({
        url: '/BaseDepartment/Tree?companyId=' + companyId + '&v=' + Math.random(),
        method: 'get'
      }).then((res) => {
        that.treeData = res.data;
        that.parentSelect = res.data;
      });
    },
    submit() {
      let that = this;

      this.$refs.formRef.validate((valid) => {
        if (!valid) return;

        submitDepartment(this.formData).then((res) => {
          if (res.code === 200) {
            that.$message({
              type: "success",
              message: res.content,
            });
            that.formDialogVisible = false;

            that.getTree(that.forms.companyID);
          }
        });
      });
    },
    toggleRowExpand() {
      let that = this;
      this.treeData.forEach(function (row) {
        that.$refs.table.toggleRowExpansion(row);
      });
    },

    del(row) {
      let that = this;
      this.$confirm("此操作将删除本页选择数据, 是否继续?", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning", })
        .then(() => {
          delDepartment(row.id).then((res) => {
            if (res.code === 200) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.getTree(that.forms.companyID);
            }
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "删除失败", });
        });
    },
    search() {
      let that = this;
      that.getTree(that.forms.companyID);
    }
  },
  mounted() {
    let that = this;

    this.$kaung.fetch({
      url: '/BaseCompany/Tree?v=' + Math.random(),
      method: 'get'
    }).then((res) => {
      that.companys = res.data;
      that.forms.companyID = res.data[0].id;
      that.getTree(that.forms.companyID);
    });
    // this.$kaung.companys().then((res) => {
    //   that.companys = res;
    //   that.forms.companyID = res[0].id;
    //   that.getTree(that.forms.companyID);
    // });

  },
};
</script>